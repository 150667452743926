<template>
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.show_home_hero"
      name="SectionHomeHero"
    />

    <BaseThemeComponent
      v-if="thePage.contents.tagline_title"
      name="PageSection"
      section-name="tagline"
      :title="thePage.contents.tagline_title"
    />

    <BaseThemeComponent
      class="blue-oak-menu-section"
      name="PageSection"
      bg-color="white"
      full-width
    >
      <VLayout row justify-center>
        <VFlex xs12>
          <BaseLocationContent
            content-key="menu_title"
            default-content="Blue Oak's Menu"
            content-class="header-4"
            tag-class="bloak-pink--text text-xs-center"
            tag="h1"
          />
          <!-- TODO (ESS) : don't hardcode loc slug -->
          <WithLocation
            v-slot="{ isLocationLoading, theLocation }"
            slug="blue-oak-bbq"
          >
            <WithLayout
              v-slot="{ isLayoutLoading, theLayout }"
              slug="blue-oak-web-menu"
            >
              <LayoutGrid
                v-if="!isLayoutLoading"
                :location="theLocation"
                :layout="theLayout"
                class="blue-oak-web-menu"
              />
            </WithLayout>
          </WithLocation>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent name="SectionLocations" />

    <BaseThemeComponent
      v-if="thePage.contents.show_home_catering"
      name="CateringCTA"
    />
    <BaseThemeComponent
      v-if="thePage.contents.ns_2_title"
      section-id="2"
      name="PageSection"
      :bg-color="thePage.contents.ns_2_bg_color"
      class="pa-0 grid-list-xs home-merch-section"
      fluid
      full-bleed-bg
      full-bleed-content
    >
      <VLayout row wrap justify-center class="pa-0">
        <VFlex xs12 md4 class="text-xs-center py-5 align-self-center">
          <h3 class="white--text header-2">
            <BasePageContent content-key="ns_2_title" />
          </h3>
          <BaseButtonOutline
            v-if="thePage.contents.ns_2_btn_text"
            :to="thePage.contents.ns_2_btn_route"
            :href="thePage.contents.ns_2_btn_link"
            large
          >
            {{ thePage.contents.ns_2_btn_text }}
          </BaseButtonOutline>
        </VFlex>
        <VFlex xs12 md8>
          <VCard flat class="img-card" :img="thePage.contents.ns_2_img" />
        </VFlex>
      </VLayout>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.show_home_contact"
      name="PageSection"
      :title="thePage.contents.contact_title"
      section-name="contact"
    >
      <VFlex class="text-xs-center pt-4">
        <BaseButtonSendMessage
          large
          component="BaseButtonOutline"
          outline-color="#1a1d48"
          >{{ thePage.contents.contact_btn_text }}</BaseButtonSendMessage
        >
      </VFlex>
    </BaseThemeComponent>

    <BaseThemeComponent
      v-if="thePage.contents.show_home_instagram"
      name="SectionInstagram"
      section-name="homeInstagram"
    />

    <BaseThemeComponent
      v-if="
        thePage.contents.ns_3_title ||
          thePage.contents.ns_3_description ||
          thePage.contents.ns_3_img
      "
      name="PageSectionNarrative"
      section-id="3"
      class="white--text"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_3_description"
            tag="p"
            class="r-headline"
          />
          <BaseButtonOutline
            v-if="thePage.contents.ns_3_btn_text"
            :outline-color="thePage.contents.ns_3_btn_outline_color"
            class="ml-0"
            :to="thePage.contents.ns_3_btn_route"
            :href="thePage.contents.ns_3_btn_link"
          >
            {{ thePage.contents.ns_3_btn_text }}
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>
    <BaseMenuComponent name="MenuSnackbar" />
  </div>
</template>

<script>
import PageMixin from '@mixins/page'
import LayoutGrid from '@components/Layout/LayoutGrid'

export default {
  name: 'BlueOakHome',
  components: { LayoutGrid },
  mixins: [PageMixin],
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';

.home-merch-section {
  .img-card {
    height: 100%;
    min-height: 33vh;
    border-radius: 0;
  }
}

.blue-oak-web-menu {
  &.grid {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1200px) {
      grid-template-areas: '. left center .' '. right . .' !important;
      grid-template-columns: 1fr 4fr 4fr 1fr !important;
    }

    @media (max-width: 900px) {
      grid-template-areas: 'left center' 'right .' !important;
      grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 650px) {
      grid-template-areas: 'left' 'center' 'right' !important;
      grid-template-columns: 1fr !important;
    }
  }
}
.blue-oak-menu-section .section-content > .container {
  @media (max-width: 900px) {
    padding: 7px;
  }
  @media (max-width: 650px) {
    padding: 3px;
  }
}
</style>
