var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.thePage.contents.show_home_hero)?_c('BaseThemeComponent',{attrs:{"name":"SectionHomeHero"}}):_vm._e(),(_vm.thePage.contents.tagline_title)?_c('BaseThemeComponent',{attrs:{"name":"PageSection","section-name":"tagline","title":_vm.thePage.contents.tagline_title}}):_vm._e(),_c('BaseThemeComponent',{staticClass:"blue-oak-menu-section",attrs:{"name":"PageSection","bg-color":"white","full-width":""}},[_c('VLayout',{attrs:{"row":"","justify-center":""}},[_c('VFlex',{attrs:{"xs12":""}},[_c('BaseLocationContent',{attrs:{"content-key":"menu_title","default-content":"Blue Oak's Menu","content-class":"header-4","tag-class":"bloak-pink--text text-xs-center","tag":"h1"}}),_c('WithLocation',{attrs:{"slug":"blue-oak-bbq"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLocationLoading = ref.isLocationLoading;
var theLocation = ref.theLocation;
return [_c('WithLayout',{attrs:{"slug":"blue-oak-web-menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLayoutLoading = ref.isLayoutLoading;
var theLayout = ref.theLayout;
return [(!isLayoutLoading)?_c('LayoutGrid',{staticClass:"blue-oak-web-menu",attrs:{"location":theLocation,"layout":theLayout}}):_vm._e()]}}],null,true)})]}}])})],1)],1)],1),_c('BaseThemeComponent',{attrs:{"name":"SectionLocations"}}),(_vm.thePage.contents.show_home_catering)?_c('BaseThemeComponent',{attrs:{"name":"CateringCTA"}}):_vm._e(),(_vm.thePage.contents.ns_2_title)?_c('BaseThemeComponent',{staticClass:"pa-0 grid-list-xs home-merch-section",attrs:{"section-id":"2","name":"PageSection","bg-color":_vm.thePage.contents.ns_2_bg_color,"fluid":"","full-bleed-bg":"","full-bleed-content":""}},[_c('VLayout',{staticClass:"pa-0",attrs:{"row":"","wrap":"","justify-center":""}},[_c('VFlex',{staticClass:"text-xs-center py-5 align-self-center",attrs:{"xs12":"","md4":""}},[_c('h3',{staticClass:"white--text header-2"},[_c('BasePageContent',{attrs:{"content-key":"ns_2_title"}})],1),(_vm.thePage.contents.ns_2_btn_text)?_c('BaseButtonOutline',{attrs:{"to":_vm.thePage.contents.ns_2_btn_route,"href":_vm.thePage.contents.ns_2_btn_link,"large":""}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_2_btn_text)+" ")]):_vm._e()],1),_c('VFlex',{attrs:{"xs12":"","md8":""}},[_c('VCard',{staticClass:"img-card",attrs:{"flat":"","img":_vm.thePage.contents.ns_2_img}})],1)],1)],1):_vm._e(),(_vm.thePage.contents.show_home_contact)?_c('BaseThemeComponent',{attrs:{"name":"PageSection","title":_vm.thePage.contents.contact_title,"section-name":"contact"}},[_c('VFlex',{staticClass:"text-xs-center pt-4"},[_c('BaseButtonSendMessage',{attrs:{"large":"","component":"BaseButtonOutline","outline-color":"#1a1d48"}},[_vm._v(_vm._s(_vm.thePage.contents.contact_btn_text))])],1)],1):_vm._e(),(_vm.thePage.contents.show_home_instagram)?_c('BaseThemeComponent',{attrs:{"name":"SectionInstagram","section-name":"homeInstagram"}}):_vm._e(),(
      _vm.thePage.contents.ns_3_title ||
        _vm.thePage.contents.ns_3_description ||
        _vm.thePage.contents.ns_3_img
    )?_c('BaseThemeComponent',{staticClass:"white--text",attrs:{"name":"PageSectionNarrative","section-id":"3","text-cols":7,"fluid":""}},[_c('VLayout',{attrs:{"row":""}},[_c('VFlex',{attrs:{"xs12":"","md10":""}},[_c('BasePageContent',{staticClass:"r-headline",attrs:{"content-key":"ns_3_description","tag":"p"}}),(_vm.thePage.contents.ns_3_btn_text)?_c('BaseButtonOutline',{staticClass:"ml-0",attrs:{"outline-color":_vm.thePage.contents.ns_3_btn_outline_color,"to":_vm.thePage.contents.ns_3_btn_route,"href":_vm.thePage.contents.ns_3_btn_link}},[_vm._v(" "+_vm._s(_vm.thePage.contents.ns_3_btn_text)+" ")]):_vm._e()],1)],1)],1):_vm._e(),_c('BaseMenuComponent',{attrs:{"name":"MenuSnackbar"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }